
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseText extends Vue {
  @Prop({ type: String, required: true })
  private text!: string;

  @Prop({ type: Boolean, default: false })
  private h1!: boolean;

  @Prop({ type: Boolean, default: false })
  private h2!: boolean;

  @Prop({ type: Boolean, default: false })
  private h3!: boolean;

  @Prop({ type: Boolean, default: false })
  private h4!: boolean;

  @Prop({ type: Boolean, default: false })
  private h5!: boolean;

  @Prop({ type: Boolean, default: false })
  private body1!: boolean;

  @Prop({ type: Boolean, default: false })
  private body2!: boolean;

  @Prop({ type: Boolean, default: false })
  private body3!: boolean;

  @Prop({ type: Boolean, default: false })
  private body4!: boolean;

  /* Reused vuetify text formatting */

  @Prop({ type: Boolean, default: false })
  private black!: boolean;

  @Prop({ type: Boolean, default: false })
  private dark!: boolean;

  @Prop({ type: Boolean, default: false })
  private bold!: boolean;

  @Prop({ type: Boolean, default: false })
  private medium!: boolean;

  @Prop({ type: Boolean, default: true })
  private regular!: boolean;

  @Prop({ type: Boolean, default: false })
  private light!: boolean;

  @Prop({ type: Boolean, default: false })
  private thin!: boolean;

  @Prop({ type: Boolean, default: false })
  private italic!: boolean;

  @Prop({ type: Boolean, default: false })
  private orange!: boolean;

  @Prop({ type: Boolean, default: false })
  private red!: boolean;

  @Prop({ type: Boolean, default: false })
  private teal!: boolean;

  private get variantClasses() {
    return {
      "-h1": this.h1,
      "-h2": this.h2,
      "-h3": this.h3,
      "-h4": this.h4,
      "-h5": this.h5,
      "-body1": this.body1,
      "-body2": this.body2,
      "-body3": this.body3,
      "-body4": this.body4,
      "font-weight-black": this.black,
      "font-weight-bold": this.bold,
      "font-weight-medium": this.medium,
      "font-weight-regular": this.regular,
      "font-weight-light": this.light,
      "font-weight-thin": this.thin,
      "font-italic": this.italic,
      "-orange": this.orange,
      "-red": this.red,
      "-teal": this.teal,
      "black--text": this.dark,
    };
  }
}
