import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY || "",
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  appVersion: "1.5.7",
});

export default Bugsnag;
